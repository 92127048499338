import { russianPlural } from './russianPlural'

export default defineI18nConfig(() => ({
  legacy: false,
  lazy: true,
  messages: {},
  pluralRules: {
    ru: russianPlural,
  },
  pluralizationRules: {
    ru: russianPlural,
  },
}))
